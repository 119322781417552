import { Button, CircularProgress, Paper } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { ALL_COMPANIES } from '@/components/CompanySwitch/CompanySwitch'
import { Context } from '@/context'
import { useGetActiveCompanies } from '@/domains/companies/hooks'
import { getCustomDealItemArgs } from '@/domains/customDealForms/helpers'
import {
  CustomDealItems,
  CustomDealOverview,
  customDealSchema,
} from '@/domains/customDeals/components/CustomDealDetail'
import {
  CustomDealArgs,
  CustomDealItem,
  checkIsCarPawn,
  sumTotalRequestedPayoutAmount,
} from '@/domains/customDeals/helpers'
import { useCreateCustomDeal } from '@/domains/customDeals/hooks'
import styles from '@/domains/customDeals/index.module.scss'
import { useGetCustomer } from '@/domains/customers/hooks'
import { useMutationShowingErrors, useParseErrors } from '@/hooks'
import {
  CustomDeal,
  CustomDealItemDataEntry,
  EDealType,
  ETransportMode,
  Employee,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'

interface Props {
  onConfirm?: (deal: CustomDeal) => void
  currentUser: Employee
}

const getInitialCustomDeal = (): CustomDealArgs => ({
  items: [],
  contactData: {
    phone: '',
    email: '',
  },
  notes: [],
  customerId: undefined,
  companyId: undefined,
  dealType: EDealType.Pawn,
  durationInDays: 30,
  pickupTransportMode: ETransportMode.Shop,
  isReversedFeeCalculation: false,
})

const defaultMinimumPawnDuration = 30

export function CustomDealCreatePage({ currentUser }: Props) {
  const history = useHistory()
  const { showErrors } = useContext(Context)
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const companyId = useAppSelector((state) => state.ui.companyId)
  const parseErrors = useParseErrors()

  const createCustomDeal = useMutationShowingErrors({
    mutation: useCreateCustomDeal(),
    successMessage: t('create_custom_deal_successful'),
  })

  const [minimumPawnDuration, setMinimumPawnDuration] = useState<number>(
    defaultMinimumPawnDuration,
  )

  const onSaveCustomDeal = (customDeal: CustomDealArgs) => {
    setLoading(true)
    createCustomDeal({
      variables: {
        customDealCreateArgs: {
          ...customDeal,
          isReversedFeeCalculation: customDeal.items.some(
            (item) =>
              item.overwrittenPawnPayoutAmount ||
              item.overwrittenPurchasePayoutAmount,
          ),
          employeeId: currentUser._id,
          durationInDays: Number(customDeal.durationInDays),
          items: customDeal.items.map((item) => getCustomDealItemArgs(item)),
        },
      },
      onCompleted: (data) => {
        history.replace(`/inApp/CustomDeals/edit/${data.createCustomDeal._id}`)
        setLoading(false)
      },
      onError: (error) => {
        const errors = parseErrors(error)
        showErrors(errors)
        setLoading(false)
      },
    })
  }

  const { companies } = useGetActiveCompanies()

  const formik = useFormik<CustomDealArgs>({
    initialValues: getInitialCustomDeal(),
    enableReinitialize: true,
    onSubmit: onSaveCustomDeal,
    validationSchema: customDealSchema,
  })

  const customDeal = formik.values

  useEffect(() => {
    if (customDeal?.companyId && companies) {
      const company = companies.find((c) => c._id === customDeal.companyId)
      if (company) {
        const pawnDuration =
          company.configuration.minimumPawnDuration ??
          defaultMinimumPawnDuration
        setMinimumPawnDuration(pawnDuration)
        onChangeField('durationInDays', pawnDuration)
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDeal?.companyId, companies])

  useEffect(() => {
    if (customDeal.companyId) return

    const getDefaultCompanyId = () => {
      if (companyId !== ALL_COMPANIES) return companyId
      return currentUser?.company?._id
    }

    const defaultCompanyId = getDefaultCompanyId()

    if (defaultCompanyId) formik.setFieldValue('companyId', defaultCompanyId)
  }, [customDeal.companyId, companyId, currentUser, formik])

  const isCarPawn = checkIsCarPawn(customDeal.items)

  const totalPayoutAmount = sumTotalRequestedPayoutAmount(
    customDeal.items,
    customDeal.dealType,
  )

  const { customer } = useGetCustomer({
    variables: {
      customerId: customDeal.customerId,
    },
    skip: !customDeal.customerId,
  })

  const onRemoveItem = (index: number) => {
    const newItems = [...customDeal.items]
    newItems.splice(index, 1)
    formik.setFieldValue('items', newItems)
  }

  const onChangeField = (
    field: string,
    value: string | number | null | boolean,
  ) => {
    formik.setFieldValue(field, value)
  }

  const onAddItem = (item: CustomDealItem) => {
    const newItems = [...customDeal.items, item]
    formik.setFieldValue('items', newItems)
  }

  const onUpdateItem = (item: CustomDealItem, index: number) => {
    const newItems = [...customDeal.items]
    newItems.splice(index, 1, item)
    formik.setFieldValue('items', newItems)
  }

  return (
    <div className={`${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <CircularProgress size={40} variant="indeterminate" />
        </div>
      )}

      <div className={styles.backToList}>
        <Link to={`/inApp/CustomDeals`}>
          <ArrowBackIos />
          <span>{t('back_to_list')}</span>
        </Link>
      </div>

      <Paper>
        <CustomDealOverview
          companies={companies ?? []}
          customDeal={{
            ...customDeal,
            items: customDeal.items as CustomDealItemDataEntry[],
          }}
          customer={customer}
          onChangeField={onChangeField}
          onEventChange={() => undefined}
          errors={formik.errors}
          setFieldError={formik.setFieldError}
          isCarPawn={isCarPawn}
          totalPayoutAmount={totalPayoutAmount}
          minimumPawnDuration={minimumPawnDuration}
        />
      </Paper>

      <Paper>
        <CustomDealItems
          companyId={customDeal.companyId}
          items={customDeal.items}
          dealType={customDeal.dealType}
          durationInDays={customDeal.durationInDays}
          isCarPawn={isCarPawn}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          onUpdateItem={onUpdateItem}
          onChangeField={onChangeField}
          errors={formik.errors.items}
          minimumPawnDuration={minimumPawnDuration}
          isLoading={isLoading}
        />
      </Paper>

      <form
        className={`u-flex u-jc-end ${styles.actionBar}`}
        onSubmit={formik.handleSubmit}
      >
        <Button
          className={`${styles.actionButton} u-mr-sm`}
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => formik.resetForm()}
          style={{ width: 200 }}
          type="button"
        >
          {t('reset')}
        </Button>
        <Button
          disabled={!formik.isValid}
          className={styles.actionButton}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          style={{ width: 200 }}
        >
          {t('save')}
        </Button>
      </form>
    </div>
  )
}
